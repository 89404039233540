*, *:before, *:after
  box-sizing: inherit

body
  font-family: Montserrat, Arial, Helvetica, sans-serif
  background-image: url('./assets/background_1.jpg')
  background-position: top center
  padding: 0
  font-size: 10px
  box-sizing: border-box

h1, h2, h3, h4
  margin: 0

.app
  width: 100%
  padding: 1em 2em
  margin: 0 auto

  @media (min-width: 1040px)
    width: 100em
    padding: 6em 2em

header
  position: relative

  .card
    width: 30em
    text-align: center
    margin: auto

    @media (min-width: 1040px)
      margin: 0 0 14em

    h1
      font-size: 3em
      font-weight: 700

    h2
      font-size: 1.5em
      font-weight: 400
      color: #666

    img
      width: 70%
      margin: 2em 0 0 0

    h3
      font-size: 1.6em
      margin: .6em 0 0 0
      font-weight: 400

    a
      color: #a30101
      text-decoration: none

  .welcome-message
    text-align: center
    margin: 3em 0

    @media (min-width: 1040px)
      position: absolute
      width: 63em
      top: 6em
      left: 35em
      font-weight: 400
      text-align: left

    .quote-marks
      display: none

      @media (min-width: 1040px)
        display: block
        position: absolute
        z-index: -1
        top: -1em
        left: -1.2em
        width: 3.4em
        height: 2.7em
        fill: #DDD

    span
      font-size: 1.7em

    .hello
      font-size: 2em
      font-weight: 700
      margin: 0 0 .5em 0

    p
      line-height: 2.1em

      strong
        font-weight: 600

main
  .company-details
    margin: 3em 0 0 0

    .company
      font-size: 2.4em
      font-weight: 700

    .position
      font-size: 1.9em
      color: #666

    .duration
      margin: .6em 0 0 0
      font-size: 1.4em
      color: #666

    ul
      padding: 0 0 0 3em

      li
        font-size: 1.6em
        margin: .6em 0

    .screenshots
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: 2em 0
      padding: 1em 0 0
      border: thin solid black

      img
        height: 10em
        margin: 0 1em 1em 0
        opacity: .5
        cursor: pointer

.heading
  span
    display: block
    font-size: 2.4em
    font-weight: 700
    padding: 0 0 .250em 0

  .decoration
    position: relative
    width: 100%
    height: .2em
    background: black

    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      display: block
      width: 5em
      height: .8em
      background: black

    &:after
      content: ''
      position: absolute
      top: 0
      left: 5em
      display: block
      width: 0
      height: 0
      border-left: .8em solid black
      border-right: .8em solid transparent
      border-bottom: .8em solid transparent

.skills
  margin: 5em 0 0

  .skills-header
    position: relative
    display: inline-block
    background: black
    color: white
    padding: .8em 3em

    &::after
      content: ''
      position: absolute
      top: 0
      right: 0
      display: block
      width: 0
      height: 0
      border-left: .8em solid transparent
      border-right: .8em solid white
      border-bottom: .8em solid transparent

    span
      font-size: 1.6em

  .skills-body
    display: flex
    flex-wrap: wrap
    position: relative
    padding: 3em 0
    border: .2em solid black
    border-top: none
    background: rgba(255, 255, 255, .5)

    @media (min-width: 1040px)
      padding: 0

    .heading
      position: absolute
      top: 0
      left: 0
      width: 100%

    .skills-body-col
      width: 30em
      padding: 0em 3em

      @media (min-width: 1040px)
        width: 33.3%
        padding: 3em 3em

      ul
        margin: 0
        padding: 0

        li
          margin: 0 0 .2em 2em

          span
            font-size: 1.6em

          &.strong
            font-weight: 600
            list-style: none
            margin-left: 0

.other
  display: flex
  margin: 5em 0 0

  .education
    width: 47.5%
    margin: 0 2.5% 0 0

    .school
      margin: 1em 0
      font-size: 1.6em

      &:nth-child(2)
        margin-top: 2em

      .name
        font-weight: 600

      .field

      .duration
        color: #666
        font-size: .8em

  .languages
    width: 47.5%
    margin: 0 0 0 2.5%

    .language
      margin: 1em 0

      &:nth-child(2)
        margin-top: 3em

      span
        font-size: 1.6em
